<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="10">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="DGAuth > rôles des clients" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear
          indeterminate
          :active="loading || running"
        ></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>Consulter les rôles associés aux clients</div>

              <v-spacer></v-spacer>

              <!-- Bouton de mode modification   -->
              <v-btn
                v-if="canEditRole && !modeEdition"
                icon
                color="primary"
                @click="clickOnModeEdition"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn
                  class="btn"
                  color="primary"
                  text
                  @click="clickOnCancelEdit"
                  :disabled="loading"
                >
                  Quitter l'édition
                </v-btn>

                <v-btn
                  icon
                  color="primary"
                  :disabled="!hasChanged || loading"
                  @click="save"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="availableItems"
              item-key="name"
              :search="search"
              :custom-filter="filterOnlyCapsText"
              disable-pagination
              hide-default-footer
            >
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template
                v-for="head in headers"
                v-slot:[`item.${head.value}`]="{ item }"
              >
                <div :key="head.value">
                  <!-- Colonne "rôle" on affiche le role -->
                  <div v-if="head.value == 'role'">
                    {{ item[head.value] }}
                  </div>

                  <!-- Colonne color on affiche un logo rose -->
                  <div v-else-if="head.value == 'color'">
                    <div v-if="itemColor(item)">
                      <v-avatar color="primary" size="10" />
                    </div>

                    <div v-if="!itemColor(item)">
                      <v-avatar color="#CCCCCC" size="10" />
                    </div>
                  </div>

                  <!-- Pour les autres colonnes, on traite l'affichage -->
                  <div v-else>
                    <v-row no-gutters>
                      <v-icon
                        v-if="item[head.value].hasRole && !modeEdition"
                        small
                        class="mr-2"
                        color="primary"
                      >
                        mdi-check
                      </v-icon>

                      <v-checkbox
                        v-if="modeEdition && !item[head.value].isHerited"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-checkbox-outline"
                        v-model="item[head.value].hasRole"
                        color="primary"
                        hide-details
                        class="my-0"
                        @click="onClickCheckBox(item['role'], head.value)"
                      ></v-checkbox>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="item[head.value].isHerited">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-account-switch-outline
                          </v-icon>
                        </template>
                        <span>Héritage par rôle supérieur</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </template>

              <template v-slot:top>
                <v-row class="mb-2">
                  <v-checkbox
                    class="mx-2"
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="viewAllRole"
                    hide-details
                    label="Afficher les rôles non associés"
                  />

                  <v-text-field
                    v-model="search"
                    label="Rechercher un rôle"
                    class="mx-4 ml-16"
                  ></v-text-field>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="ma-2 px-4 btn"
              color="primary"
              :disabled="!hasChanged || loading"
              @click="save"
            >
              <div class="capitalize">enregistrer</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";

import DgAuthService from "@/service/dgauth/dgauth_service.js";
import AuthAdminService from "@/service/backend/auth_admin_service.js";

import * as logger from "@/tools/logger.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "CustomerRolesDgauth",

  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    AlertNotSavedModifsMixin,
    SnackBarMixin,
  ],

  data() {
    return {
      /** L'object service métier */
      serviceDgAuth: null,
      serviceAuthAdminBackend: null,

      /** variable pour l'affichage de chargement de données */
      loading: false,
      running: false,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /** Fait aparaître ou non les rôles qui ne sont pas affectés */
      viewAllRole: false,

      /** Tableau des headers de la vue table */
      headers: [],

      /** Remplissage de la matrice */
      items: [],
      /** les éléments source de la matrice */
      itemsSource: [],

      /** Tableau des rôles héritage compris */
      rolesApmSource: [],

      /** Tableau des rôles pour les customers */
      customers: [],

      // variable pour le champ de recherche
      search: "",

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditDgauth],
    };
  },
  methods: {
    /** Méthode de chargement de la vue */
    async load() {
      try {
        logger.debug("Chargement des données de la vue.");
        this.loading = true;

        // Récupération des rôles dgauth avec héritage
        this.rolesApmSource = await this.serviceAuthAdminBackend.getRoles();
        // logger.debug("Roles apm", this.rolesApmSource[0]);

        // Récupération des rôles pour les client
        this.customers =
          await this.serviceAuthAdminBackend.getRolesForCustomer();
        // logger.debug("Customers", this.customers);

        // Construction des headers de la matrice
        this.buildHeaders();
        // Construction des rows (héritage compris)
        this.buildItems();

        this.itemsSource = [];
        this.itemsSource = JSON.parse(JSON.stringify(this.items));
      } catch (error) {
        logger.error("Erreur de chargement des datas.  " + error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Construction des headers de la table */
    buildHeaders() {
      // Ajout de la colonne des rôles
      this.headers.push({
        text: "Rôle",
        value: "role",
        width: "150px",
      });

      // Ajout de la colonne des rôles
      this.headers.push({
        text: "",
        value: "color",
        width: "50px",
        sortable: false,
      });

      // Ajout de la colonne des clients
      this.headers.push({
        text: "Client",
        value: "customer",
        sortable: false,
      });
    },

    /** Construction des lignes de  la matrice */
    buildItems() {
      this.items = [];

      // Récupération des droits hérités
      let roleCustomer = this.serviceDgAuth.buildInheritance(
        this.rolesApmSource,
        this.customers.rules.authorized
      );
      // logger.debug("Roles avec héritage", roleCustomer);

      // Parcours de l'ensemble des rôles pour la construction des rows
      for (let role of this.rolesApmSource) {
        let row = {
          role: "",
          color: false,
          customer: {
            hasRole: false,
            isHerited: false,
          },
        };

        row.role = role.name;
        // Tente de récupérer le role en cours dans ceus du customer
        let foundRole = roleCustomer.find((r) => r.name == role.name);
        if (foundRole) {
          if (foundRole.herited) {
            row.customer.isHerited = true;
          } else {
            row.customer.hasRole = true;
          }
        }
        this.items.push(row);
      }

      // Parcours de l'ensemble des rôles pour y inscrire ceux hérités
      for (let item of this.items) {
        // On ne traite que les rôles cochés
        if (item.customer.hasRole) {
          // Récupération du roles en cours dans la tableau source des rôles
          let roleSrc = this.rolesApmSource.find((r) => r.name == item.role);

          // Parcours des rôles hérités
          for (let roleGrant of roleSrc.grant) {
            // Récupération de la row concernée par le role
            let findItem = this.items.find((i) => i.role == roleGrant);
            // La row est héritée
            findItem.customer.isHerited = true;
          }
        }
      }
    },

    // Sauvegarde de la matrice
    async save() {
      try {
        this.loading = true;
        logger.debug("SAVE matrice");

        // Récupération de toutes les rows qui ont un check rôle
        let roles = this.items.filter((i) => i.customer.hasRole);
        // Récupération des roles des rows
        let customerRoles = roles.map((r) => r.role);

        // Update de l'objet issu de la bd
        this.customers.rules.authorized = customerRoles;

        // Sauvegarde en base
        await this.serviceAuthAdminBackend.updateRolesForCustomer(
          this.customers
        );

        this.itemsSource = JSON.parse(JSON.stringify(this.items));
        this.buildItems();
        this.modeEdition = false;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Trouve tous les rôles qui ont changés et les retourne en un tableau */
    findCustomerHasChanged() {
      let roleHasChanged = [];

      // Parcours les rows de la matrice
      for (let row of this.items) {
        // Récupère la row source
        let itemSrc = this.itemsSource.find((i) => i.role == row.role);

        // Comparaison d'affectation du rôle
        if (itemSrc.customer.hasRole != row.customer.hasRole) {
          roleHasChanged.push(row.role);
        }
      }
      return roleHasChanged;
    },

    /** Retourne vrai si l'utilisateur possède le rôle */
    itemColor(item) {
      if (item.customer.hasRole || item.customer.isHerited) {
        return true;
      } else {
        return false;
      }
    },

    /** Traite le click sur une checkbox
     * Reçois la ligne et la colonne
     * en fonction, attribu ou non l'héritage
     */
    onClickCheckBox(row, col) {
      // Récupère la ligne dans la liste d'items
      let item = this.items.find((i) => i.role == row);

      // Récupère l'état du check de la cellule
      let check = item[col].hasRole;

      // récupère le rôle dans la liste originale
      let roleApm = this.rolesApmSource.find((r) => r.name == row);

      // Parcours la liste des rôles hérités
      for (let role of roleApm.grant) {
        // Récupère la row héritée
        let it = this.items.find((i) => i.role == role);
        // La valeur d'héritage est mise à la même valeur que celle du parent
        it[col].isHerited = check;

        // Si le rôle hérité était déjà check, on met la valeur à faux.
        if (check) {
          it[col].hasRole = false;
        }
      }
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.items = JSON.parse(JSON.stringify(this.itemsSource));
          }
        };
      } else {
        this.modeEdition = false;
      }
    },

    /** Méthode de recherche d'un rôle */
    filterOnlyCapsText(value, search) {
      search = search.toUpperCase();

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    /** Evènement d'activation du mode édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },
  },
  computed: {
    /** Retourne soit les rôles qui ont au moins une association, soit tous les rôles */
    availableItems() {
      let items = [];

      // Si la coche "tous les rôles" est active, on retourne TOUS les rôles
      if (this.viewAllRole) {
        return this.items;
      }

      // Parcours l'ensemble des rows
      for (let item of this.items) {
        let addItem = false;

        // Parcours les colonnes de la row
        for (let i in item) {
          if (i == "role") {
            continue;
          } else {
            // On trouve une cellule settée, pas besoin de continuer à analyser les autres colonnes
            if (item[i].hasRole || item[i].isHerited) {
              addItem = true;
              break;
            }
          }
        }
        // Au moins une cellule settée trouvée, on ajoute l'item
        if (addItem) {
          items.push(item);
        }
      }
      return items;
    },

    /** retourne vrai si un des items à changé par rapport à sa source */
    hasChanged() {
      let changed = false;

      /** Le tableau source est à null --> le modèle ne peut avoir changé */
      if (!this.rolesApmSource) return false;

      let find = this.findCustomerHasChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.serviceDgAuth = new DgAuthService(this.$api.getDgAuthApi());
    this.serviceAuthAdminBackend = new AuthAdminService(
      this.$api.getAuthAdminApi()
    );

    this.load();
  },
};
</script>
